import React, { useContext, useEffect, useRef, useState } from 'react'

// Components
import Modal from '../../organisms/Modal'
import FormAuthor from '../../organisms/FormAuthor'
import FormPublisher from '../../organisms/FormPublisher'
import RegisterHome from '../../organisms/RegisterHome'
import SuccsessModal from '../../molecules/SuccessModal'
import ModalContext from '../../../context/Modal/Modal.context'

// Context
import RegisterContext from '../../../context/Register/Register.context'

// Style
import { register, wrapper } from './style.module.scss'
import FormChapter from '../../organisms/FormChapter'
import FormOneshot from '../../organisms/FormOneShot'
import FormSeries from '../../organisms/FormSeries'
import FormSofa2024 from '../../organisms/FormSofa2024'
import AuthModal from '../../molecules/AuthModal'
import Registerwork from '../../organisms/RegisterWork'

export type RegisterForm =
  | 'Home'
  | 'Author'
  | 'Publisher'
  | 'Work'
  | 'OneShot'
  | 'Novel'
  | 'Series'
  | 'Chapter'
  | 'Volume'
  | 'SOFA2024'

const Register: React.FC = () => {
  const [arr, setArr] = useState<RegisterForm[]>(['Home'])

  const ref = useRef<HTMLDivElement | null>(null)

  const {
    types,
    cities,
    series,
    genres,
    authors,
    demAges,
    auxTeam,
    warnings,
    statuses,
    demSexes,
    auxGenres,
    ageRanges,
    countries,
    publishers,
    departments,
    publications,
    distributions,
    updateTypes,
    updateSeries,
    updateCities,
    updateGenres,
    updateDemAges,
    updateAuxTeam,
    updateAuthors,
    updateAuxGenre,
    updateDemSexes,
    updateStatuses,
    updateWarnings,
    updateAgeRanges,
    updateCountries,
    updatePublishers,
    updateDepartments,
    updatePublications,
    updateDistributions,
  } = useContext(RegisterContext)

  const { closeModal, openModal } = useContext(ModalContext)

  const openForm = (form: RegisterForm) => {
    if (ref) {
      const timeout = setTimeout(() => {
        ref.current?.scrollTo(0, 0)
        clearTimeout(timeout)
      }, 700)
    }

    setArr([...arr, form])
  }

  const closeForm = () => {
    setArr(arr.slice(0, arr.length - 1))
  }

  useEffect(() => {
    if (types?.length === 0) updateTypes()
    if (cities?.length === 0) updateCities()
    if (genres?.length === 0) updateGenres()
    if (demAges?.length === 0) updateDemAges()
    if (demSexes?.length === 0) updateDemSexes()
    if (statuses?.length === 0) updateStatuses()
    if (warnings?.length === 0) updateWarnings()
    if (countries?.length === 0) updateCountries()
    if (ageRanges?.length === 0) updateAgeRanges()
    if (departments?.length === 0) updateDepartments()
    if (distributions?.length === 0) updateDistributions()
    if (auxTeam?.length === 0) updateAuxTeam()
    if (auxGenres?.length === 0) updateAuxGenre()
    if (series?.length === 0) updateSeries()
    if (authors?.length === 0) updateAuthors()
    if (publishers?.length === 0) updatePublishers()
    if (publications?.length === 0) updatePublications()

    // Authorization Modal
    if (window) {
      const authorization = window.localStorage.getItem('authorization')
      if (!authorization)
        openModal(
          <AuthModal
            onClick={() => {
              if (window)
                window.localStorage.setItem(
                  'authorization',
                  `Authorized at ${new Date().toISOString()}`
                )
              closeModal()
            }}
          />,
          true
        )
    }
  }, [])

  return (
    <div className={register} ref={ref}>
      <Modal />
      <div className={wrapper}>
        <RegisterHome
          active={arr[arr.length - 1] === 'Home'}
          openForm={openForm}
        />
        <Registerwork
          active={arr[arr.length - 1] === 'Work'}
          openForm={openForm}
          closeForm={closeForm}
        />
        <FormAuthor
          active={arr[arr.length - 1] === 'Author'}
          closeForm={closeForm}
          succsessModal={
            <SuccsessModal
              buttons={[
                {
                  label: 'Inscibir otro autor',
                  onClick: () => {
                    closeModal()
                  },
                },
                {
                  label: 'Finalizar',
                  onClick: () => {
                    closeForm()
                    closeModal()
                  },
                },
              ]}
            />
          }
        />
        <FormPublisher
          active={arr[arr.length - 1] === 'Publisher'}
          closeForm={closeForm}
          succsessModal={
            <SuccsessModal
              buttons={[
                {
                  label: 'Inscibir otra editorial',
                  onClick: () => {
                    closeModal()
                  },
                },
                {
                  label: 'Finalizar',
                  onClick: () => {
                    closeForm()
                    closeModal()
                  },
                },
              ]}
            />
          }
        />
        <FormChapter
          openForm={openForm}
          active={arr[arr.length - 1] === 'Chapter'}
          closeForm={closeForm}
          succsessModal={
            <SuccsessModal
              buttons={[
                {
                  label: 'Inscibir otro capítulo',
                  onClick: () => {
                    closeModal()
                  },
                },
                {
                  label: 'Finalizar',
                  onClick: () => {
                    closeForm()
                    closeModal()
                  },
                },
              ]}
            />
          }
        />
        <FormOneshot
          active={arr[arr.length - 1] === 'OneShot'}
          closeForm={closeForm}
          succsessModal={
            <SuccsessModal
              buttons={[
                {
                  label: 'Inscibir otra obra',
                  onClick: () => {
                    closeModal()
                  },
                },
                {
                  label: 'Finalizar',
                  onClick: () => {
                    closeForm()
                    closeModal()
                  },
                },
              ]}
            />
          }
          openForm={openForm}
        />
        <FormOneshot
          novel
          active={arr[arr.length - 1] === 'Novel'}
          closeForm={closeForm}
          succsessModal={
            <SuccsessModal
              buttons={[
                {
                  label: 'Inscibir otra obra',
                  onClick: () => {
                    closeModal()
                  },
                },
                {
                  label: 'Finalizar',
                  onClick: () => {
                    closeForm()
                    closeModal()
                  },
                },
              ]}
            />
          }
          openForm={openForm}
        />
        <FormSeries
          active={arr[arr.length - 1] === 'Series'}
          closeForm={closeForm}
          openForm={openForm}
          succsessModal={
            <SuccsessModal
              buttons={[
                {
                  label: 'Inscibir otra serie',
                  onClick: () => {
                    closeModal()
                  },
                },
                {
                  label: 'Finalizar',
                  onClick: () => {
                    closeForm()
                    closeModal()
                  },
                },
              ]}
            />
          }
        />
        <FormSofa2024
          active={arr[arr.length - 1] === 'SOFA2024'}
          closeForm={closeForm}
          succsessModal={
            <SuccsessModal
              buttons={[
                {
                  label: 'Finalizar',
                  onClick: () => {
                    closeForm()
                    closeModal()
                  },
                },
              ]}
            />
          }
        />
      </div>
    </div>
  )
}

export default Register
