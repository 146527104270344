import React, { useContext, useEffect, useState, ReactNode } from 'react'

import { v4 as uuidV4 } from 'uuid'
import { AxiosError } from 'axios'
import { FormProvider, useForm } from 'react-hook-form'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTiktok,
  faThreads,
  faYoutube,
  faLinkedin,
  faInstagram,
  faXTwitter,
  faFacebook,
} from '@fortawesome/free-brands-svg-icons'

// Components
import Spinner from '../../atoms/Spinner'
import UploadImage from '../../atoms/UploadImage'
import { normalizeText } from '../../../utils/utils'
import RegisterInput from '../../atoms/RegisterInput'
import RegisterCheck from '../../atoms/RegisterCheck'
import RegisterTextarea from '../../atoms/RegisterTextArea'
import { RegisterPublisher } from '../../../services/types'
import RegisterCityPicker from '../../atoms/RegisterCityPicker'
import RegisterCountryPicker from '../../atoms/RegisterCountryPicker'

// Context
import ModalContext from '../../../context/Modal/Modal.context'
import RegisterContext from '../../../context/Register/Register.context'

// Services
import { postPublisher } from '../../../services/register'

// Style
import {
  grid,
  forms,
  close,
  formsLeft,
  formsRight,
  title,
  hidden,
  wrapper,
  spinner,
  subtitle,
  socialIcon,
  descriptions,
  expandedCell,
  submitButton,
} from './style.module.scss'

interface Props {
  succsessModal: ReactNode
  active: boolean
  closeForm: () => void
}

const FormPublisher: React.FC<Props> = ({
  succsessModal,
  active,
  closeForm,
}) => {
  const [publisherId, setPublisherId] = useState<string>('')
  const [disable, setDisable] = useState(false)
  const [img, setImg] = useState<string | null>(null)
  const [city, setCity] = useState<string | null>(null)
  const [country, setCountry] = useState<string | null>(null)
  const [international, setInternational] = useState(false)

  const { publishers, updatePublishers } = useContext(RegisterContext)
  const { openModal } = useContext(ModalContext)

  const methods = useForm<RegisterPublisher>({
    mode: 'onTouched',
  })
  const {
    reset,
    register,
    getValues,
    clearErrors,
    formState: { isValid, errors },
  } = methods

  useEffect(() => {
    setPublisherId(uuidV4())
    clearErrors()

    // Load local data
    if (window) {
      const localPublisher = window.localStorage.getItem('publisher')
      if (localPublisher) {
        const publisher = JSON.parse(localPublisher)
        reset(publisher, { keepDefaultValues: true })
        setImg(publisher.logo)
        setCountry(publisher.country)
        setCity(publisher.city)
        if (publisher.country) {
          setInternational(true)
        }
      }
    }
    // Auto-save
    const interval = setInterval(() => {
      if (window) {
        window.localStorage.setItem('publisher', JSON.stringify(getValues()))
      }
    }, 30000)

    //Clearing the interval
    return () => clearInterval(interval)
  }, [])

  const addAthor = async (data: any) => {
    data.id = publisherId
    for (let key of Object.keys(data)) {
      if (data[key] === '') {
        data[key] = undefined
      }
    }
    if (international) {
      data.city = undefined
    } else {
      data.country = undefined
    }
    try {
      await postPublisher(data)
      await updatePublishers()
      reset()
      setImg(null)
      setCountry(null)
      setCity(null)
      openModal(succsessModal)
      setPublisherId(uuidV4())
      if (window) {
        window.localStorage.setItem('publisher', JSON.stringify(getValues()))
      }
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        const msg = error.response?.data.message
        openModal(<>Error: {msg}</>)
      }
    } finally {
      setDisable(false)
    }
  }

  const onSubmit = (data: any) => {
    setDisable(true)
    openModal(<Spinner className={spinner} />)
    addAthor(data)
  }

  return (
    <div className={[wrapper, active ? undefined : hidden].join(' ')}>
      <div className={title}>
        Formulario de inscripción de editoriales
        <FontAwesomeIcon icon={faClose} className={close} onClick={closeForm} />
      </div>
      <div className={descriptions}>
        <i>
          Este formulario te permitirá inscribir una nueva editorial de cómic
          colombiano. Recuerda que cuanta más información nos proporciones,
          mejor será la impresión que los usuarios tendrán de la serie y nos
          permitirá realizar mejores análisis de la situación del cómic
          colombiano.
        </i>
      </div>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault()
          }}
        >
          <div className={forms}>
            <div className={formsLeft}>
              <div className={subtitle}>Logo de la editorial</div>
              <UploadImage
                name={'logo'}
                uuid={publisherId}
                folder={'publishers'}
                external={img}
              />
            </div>
            <div className={formsRight}>
              <div className={subtitle}>Información de la editorial</div>
              <div className={grid}>
                <RegisterInput
                  required
                  description="Nombre de la editorial"
                  label="Nombre"
                  register={register('name', {
                    required: { value: true, message: 'Campo obligatorio' },
                    validate: (v) => {
                      const duplicated = publishers.find(
                        (p) => normalizeText(p.name) === normalizeText(v)
                      )
                      if (duplicated)
                        return 'Ya existe una editorial con este nombre en nuestra base de datos'
                      return true
                    },
                  })}
                  error={errors.name?.message}
                />
                <RegisterInput
                  description="Año en el que se fundó la editorial"
                  label="Año de fundacion"
                  type="number"
                  register={register('startYear', {
                    min: {
                      value: 1800,
                      message: `La fecha debe estar entre 1800 y ${new Date().getFullYear()}`,
                    },
                    max: {
                      value: new Date().getFullYear(),
                      message: `La fecha debe estar entre 1800 y ${new Date().getFullYear()}`,
                    },
                  })}
                  error={errors.startYear?.message}
                />
                <RegisterInput
                  description="Si la editorial se cerró poner el año en el que ocurrió"
                  type="number"
                  label="Año de cierre"
                  register={register('endYear', {
                    min: {
                      value: 1800,
                      message: `La fecha debe estar entre 1800 y ${new Date().getFullYear()}`,
                    },
                    maxLength: { value: 4, message: 'Hola' },
                    max: {
                      value: new Date().getFullYear(),
                      message: `La fecha debe estar entre 1800 y ${new Date().getFullYear()}`,
                    },
                  })}
                  error={errors.endYear?.message}
                />
                <RegisterCheck
                  description="Activa esta opción si la editorial no tiene base en Colombia"
                  label="Editorial Internacional"
                  register={register('colombian', {
                    validate: (v) => {
                      setInternational(Boolean(v))
                      return true
                    },
                  })}
                  center
                  justify
                />
                {international ? (
                  <RegisterCountryPicker
                    name="country"
                    description="País base de la editorial"
                    label="País"
                    register={register('country')}
                    error={errors.country?.message}
                    external={country}
                  />
                ) : (
                  <RegisterCityPicker
                    name="city"
                    description="Ciudad base de la editorial"
                    label="Ciudad"
                    register={register('city')}
                    error={errors.city?.message}
                    external={city}
                  />
                )}
                <RegisterCheck
                  description="¿Autorizas hacer pública la información de la editorial en las páginas web de comiccolombiano.com y Bogotá Cómics? "
                  label="Autorización"
                  register={register('authorize')}
                  error={errors.authorize?.message}
                  center
                  justify
                  defaultChecked
                />
                <div className={expandedCell}>
                  <div className={subtitle}>Descripción</div>
                  <RegisterTextarea
                    description="Breve reseña de la trayectoria de la editorial. Esta es la oportunidad para presentar su trabajo al público y captar su interés. Asegúrate de transmitir su pasión y esencia artística de la mejor manera posible, destacando los momentos clave de su historia y lo que hace único su enfoque creativo."
                    register={register('description', {
                      minLength: {
                        value: 3,
                        message: 'Debe tener mas de 3 caracteres',
                      },
                    })}
                    error={errors.description?.message}
                    center
                    justify
                  />
                </div>
                <div className={subtitle}>Redes sociales</div>
                <RegisterInput
                  description="URL al perfil de Facebook (https://...)"
                  label={
                    <span>
                      <FontAwesomeIcon
                        icon={faFacebook}
                        className={socialIcon}
                      />
                      Facebook
                    </span>
                  }
                  register={register('facebook', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.facebook?.message}
                />
                <RegisterInput
                  description="URL al perfil de Instagram (https://...)"
                  label={
                    <span>
                      <FontAwesomeIcon
                        icon={faInstagram}
                        className={socialIcon}
                      />
                      Instagram
                    </span>
                  }
                  register={register('instagram', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.instagram?.message}
                />
                <RegisterInput
                  description="URL al perfil de X (https://...)"
                  label={
                    <span>
                      <FontAwesomeIcon
                        icon={faXTwitter}
                        className={socialIcon}
                      />
                      X (Twitter)
                    </span>
                  }
                  register={register('twitter', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.twitter?.message}
                />
                <RegisterInput
                  description="URL al perfil de YouTube (https://...)"
                  label={
                    <span>
                      <FontAwesomeIcon
                        icon={faYoutube}
                        className={socialIcon}
                      />
                      YouTube
                    </span>
                  }
                  register={register('youtube', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.youtube?.message}
                />
                <RegisterInput
                  description="URL al perfil de TikTok (https://...)"
                  label={
                    <span>
                      <FontAwesomeIcon icon={faTiktok} className={socialIcon} />
                      TikTok
                    </span>
                  }
                  register={register('tiktok', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.tiktok?.message}
                />
                <RegisterInput
                  description="URL al perfil de Threads (https://...)"
                  label={
                    <span>
                      <FontAwesomeIcon
                        icon={faThreads}
                        className={socialIcon}
                      />
                      Threads
                    </span>
                  }
                  register={register('threads', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.threads?.message}
                />
                <RegisterInput
                  description="URL al perfil de LinkedIn (https://...)"
                  label={
                    <span>
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        className={socialIcon}
                      />
                      LinkedIn
                    </span>
                  }
                  register={register('linkedin', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.linkedin?.message}
                />
                <RegisterInput
                  description="URL a la página de Patreon del autor (https://...)"
                  label="Patreon"
                  register={register('patreon', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.patreon?.message}
                />
                <RegisterInput
                  description="URL a la página de Vaki del autor (https://...)"
                  label="Vaki"
                  register={register('vaki', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.vaki?.message}
                />
                <RegisterInput
                  description="URL a la página del autor (https://...)"
                  label="Página de internet"
                  register={register('webpage', {
                    pattern: {
                      value:
                        /(?:https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                      message:
                        'Favor usar una URL segura (empiezan con https://..)',
                    },
                  })}
                  error={errors.webpage?.message}
                />
                <RegisterInput
                  description="E-mail de contacto"
                  label="E-mail"
                  register={register('email', {
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: 'El email no tiene el formato esperado',
                    },
                  })}
                  error={errors.email?.message}
                />
              </div>
            </div>
          </div>
          <input
            type="submit"
            disabled={!isValid || disable}
            className={submitButton}
            value="Inscibir"
          />
        </form>
      </FormProvider>
    </div>
  )
}

export default FormPublisher
