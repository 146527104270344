// extracted by mini-css-extract-plugin
export var errorLabel = "style-module--error-label--25729";
export var ghost = "style-module--ghost--6b502";
export var input = "style-module--input--a4cd8";
export var inputDescription = "style-module--input-description--a7cd0";
export var inputLabel = "style-module--input-label--8ba7c";
export var inputWrapper = "style-module--input-wrapper--b6585";
export var long = "style-module--long--92908";
export var visible = "style-module--visible--83dfd";
export var visibleElement = "style-module--visible-element--67a1d";
export var wrapper = "style-module--wrapper--d73cf";