import {
  faBookBookmark,
  faBookmark,
  faBookOpen,
  faDragon,
  faNewspaper,
  faPenFancy,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { RegisterForm } from '../../templates/Register'

// Style
import {
  grid,
  title,
  logos,
  logosImg,
  button,
  buttonIcon,
  buttonLabel,
  hidden,
  wrapper,
  descriptions,
} from './style.module.scss'

interface Props {
  active: boolean
  openForm: (form: RegisterForm) => void
}

const RegisterHome: React.FC<Props> = ({ active, openForm }) => {
  return (
    <div className={[wrapper, active ? undefined : hidden].join(' ')}>
      <div className={title}>Formulario de inscripción</div>
      <div className={descriptions}>
        <i>
          Este formulario está diseñado para registrar perfiles, editoriales y
          obras en nuestra base de datos. A través de él, editoriales, autores,
          ilustradores y otros profesionales del sector podrán inscribir sus
          obras y perfiles para ser incluidos en nuestro repositorio. Recuerda
          que cuanta más información nos proporciones, mejor será la impresión
          que los usuarios tendrán y nos permitirá realizar análisis más
          precisos sobre la situación del cómic colombiano.
        </i>
      </div>
      <div className={title}>Pasos</div>
      <div className={grid}>
        <div className={button} onClick={() => openForm('Author')}>
          <div className={buttonIcon}>
            <FontAwesomeIcon icon={faPenFancy} />
          </div>
          <div className={buttonLabel}>1. Inscribir Perfiles</div>
        </div>
        <div className={button} onClick={() => openForm('Publisher')}>
          <div className={buttonIcon}>
            <FontAwesomeIcon icon={faNewspaper} />
          </div>
          <div className={buttonLabel}>
            2. Inscribir Editoriales
            <br />
            (si aplica)
          </div>
        </div>
        <div className={button} onClick={() => openForm('Work')}>
          <div className={buttonIcon}>
            <FontAwesomeIcon icon={faBookOpen} />
          </div>
          <div className={buttonLabel}>3. Inscribir Obras</div>
        </div>
        {/* <div className={button} onClick={() => openForm('Series')}>
          <div className={buttonIcon}>
            <FontAwesomeIcon icon={faBookBookmark} />
          </div>
          <div className={buttonLabel}>3.b Inscribir Series</div>
        </div> */}
        {/* <div className={button} onClick={() => openForm('SOFA2024')}>
          <div className={buttonIcon}>
            <FontAwesomeIcon icon={faDragon} />
          </div>
          <div className={buttonLabel}>
            4. Finalizar la inscripción para la actividad de SOFA 2024
          </div>
        </div> */}
        {/* <div className={button} onClick={() => openForm('Chapter')}>
          <div className={buttonIcon}>
            <FontAwesomeIcon icon={faBookmark} />
          </div>
          <div className={buttonLabel}>
            5. Agregar Capítulo de una Series que ya esta inscrita
          </div>
        </div> */}
      </div>
      <div className={title}>Participan</div>
      <div className={logos}>
        <img
          className={logosImg}
          src="https://assets.comiccolombiano.com/register/comic-colombiano-com.jpg"
        />
        <img
          className={logosImg}
          src="https://assets.comiccolombiano.com/register/bacata.jpg"
        />
        <img
          className={logosImg}
          src="https://assets.comiccolombiano.com/register/ave-negra.jpg"
        />
        <img
          className={logosImg}
          src="https://assets.comiccolombiano.com/register/zarpo.jpg"
        />
        <img
          className={logosImg}
          src="https://assets.comiccolombiano.com/register/b-comics.jpg"
        />
        <img
          className={logosImg}
          src="https://assets.comiccolombiano.com/register/frankzine.jpg"
        />
        <img
          className={logosImg}
          src="https://assets.comiccolombiano.com/register/fist.jpg"
        />
        <img
          className={logosImg}
          src="https://assets.comiccolombiano.com/register/geek-attack.jpg"
        />
        <img
          className={logosImg}
          src="https://assets.comiccolombiano.com/register/mutant-corp.jpg"
        />
        <img
          className={logosImg}
          src="https://assets.comiccolombiano.com/register/mano-de-obra.jpg"
        />
        <img
          className={logosImg}
          src="https://assets.comiccolombiano.com/register/viñeta-violeta.jpg"
        />
        <img
          className={logosImg}
          src="https://assets.comiccolombiano.com/register/one-studio.jpg"
        />
        <img
          className={logosImg}
          src="https://assets.comiccolombiano.com/register/pow.jpg"
        />
        <img
          className={logosImg}
          src="https://assets.comiccolombiano.com/register/void.jpg"
        />
        <img
          className={logosImg}
          src="https://assets.comiccolombiano.com/register/graf-studio.jpg"
        />
      </div>
    </div>
  )
}

export default RegisterHome
