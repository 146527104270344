// extracted by mini-css-extract-plugin
export var errorLabel = "style-module--error-label--2ef02";
export var ghost = "style-module--ghost--743a2";
export var input = "style-module--input--4b891";
export var inputDescription = "style-module--input-description--8d3e4";
export var inputLabel = "style-module--input-label--39cca";
export var inputWrapper = "style-module--input-wrapper--9aa48";
export var long = "style-module--long--7a77d";
export var visible = "style-module--visible--052e7";
export var visibleElement = "style-module--visible-element--cf0f5";
export var wrapper = "style-module--wrapper--c4aae";