// extracted by mini-css-extract-plugin
export var button = "style-module--button--bd5cb";
export var buttonIcon = "style-module--button-icon--10688";
export var buttonLabel = "style-module--button-label--aecb5";
export var description = "style-module--description--b95ba";
export var descriptions = "style-module--descriptions--41841";
export var grid = "style-module--grid--7b826";
export var hidden = "style-module--hidden--1dc84";
export var logos = "style-module--logos--4d178";
export var logosImg = "style-module--logos-img--7d4bf";
export var participants = "style-module--participants--af7cc";
export var subtitle = "style-module--subtitle--db9ce";
export var title = "style-module--title--328b5";
export var wrapper = "style-module--wrapper--e446d";