import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useFormContext, UseFormRegisterReturn } from 'react-hook-form'

// Context
import RegisterContext from '../../../context/Register/Register.context'

// Style
import {
  long,
  ghost,
  input,
  inputLabel,
  inputWrapper,
  inputDescription,
  wrapper,
  errorLabel,
  groupWrapper,
} from './style.module.scss'

interface Props {
  names: string[]
  registers: UseFormRegisterReturn[]
  error?: string
  justify?: boolean
  label?: string | ReactNode
  description?: string | ReactNode
  external?: string[] | null
  required?: boolean
  setExternal?: (arr: string[]) => void
}

const RegisterGenreGroup: React.FC<Props> = ({
  names,
  label,
  error,
  justify,
  external,
  registers,
  description,
  required,
  setExternal,
}) => {
  const { genres } = useContext(RegisterContext)

  const [disabled, setDisabled] = useState<boolean>(false)
  const [genreArr, setGenreArr] = useState<string[]>([])

  const ref = useRef<HTMLInputElement | null>(null)
  const { setValue, getValues } = useFormContext()

  const setGenre = (value: boolean, id: string) => {
    if (value) {
      names.some((n, i) => {
        if (getValues(n) === id) return true
        if (getValues(n) === '') {
          setValue(n, id)
          genreArr.splice(i, 1, id)
          if (i === n.length - 1) {
            setDisabled(true)
          }
          return true
        }
        return false
      })
    } else {
      names.some((n, i) => {
        if (getValues(n) === id) {
          setValue(n, '')
          genreArr.splice(i, 1, '')
          setDisabled(false)
          return true
        }
        return false
      })
    }
    setGenreArr([...genreArr])
  }

  useEffect(() => {
    if (setExternal) setExternal([...genreArr])
  }, [genreArr])

  return (
    <div className={wrapper}>
      <div className={inputLabel}>
        {label}
        {required && ' *'}
      </div>
      <div className={[inputDescription, justify ? long : undefined].join(' ')}>
        {error ? <span className={errorLabel}>{error}</span> : description}
      </div>
      <div className={groupWrapper}>
        {genres.map((g) => (
          <div className={inputWrapper} key={`checkbox_${g.name}`}>
            <input
              type="checkbox"
              className={input}
              onChange={(e) => setGenre(e.target.checked, g.id)}
              disabled={!external?.find((s) => g.id === s) && disabled}
              checked={!!external?.find((s) => g.id === s)}
            />
            {g.name}
          </div>
        ))}
      </div>
      <input {...registers[0]} className={ghost} />
      <input {...registers[1]} className={ghost} />
      <input {...registers[2]} className={ghost} />
    </div>
  )
}

export default RegisterGenreGroup
