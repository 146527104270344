import React from 'react'

// Style
import {
  link,
  title,
  wrapper,
  button,
  button0,
  buttonContainer,
} from './style.module.scss'

type Props = {
  onClick?: () => void
}

const AuthModal: React.FC<Props> = ({ onClick }) => {
  return (
    <div className={wrapper}>
      <div className={title}>Base de datos</div>
      <div className={title}>Cómic colombiano</div>
      <div>
        Con el objetivo de avanzar con diversos proyectos en pro del cómic
        nacional, los cuales requieren una información completa y detallada del
        sector cómic: autores, procesos, obras, sellos editoriales, etc. Desde
        la comunidad de autores, encontramos pertinente la creación de una{' '}
        <b>base de datos</b>, en donde se recopile y organice la mayor cantidad
        de información acerca del cómic colombiano de manera detallada y
        accesible para su uso.
        <br />
        <br />
        Para llevar a cabo este proceso, nos encontramos realizando un esfuerzo
        mancomunado en alianza con la comunidad de autores y los diferentes
        agentes vinculados a este sector. E invitamos a toda la comunidad a
        trabajar en equipo y participar de manera activa en esta iniciativa.
        <br />
        <br />
        ¿En qué usaremos los datos recolectados?
        <br />
        <ul>
          <li>Realizar un censo de autores de comic nacional.</li>
          <li>
            Obtener resultados estadísticos del sector: trayectoria, alcance,
            crecimiento, evolución, etc.
          </li>
          <li>
            Realizar una medición del sector para gestionar actividades como el
            Festival Distrital de Cómics y otros eventos que beneficien a la
            comunidad de autores.
          </li>
          <li>
            Suministrar una base datos a <b>IDARTES</b> y al{' '}
            <b>Ministerio de Cultura</b> a través nuestros consejeros
            distritales y mesas de trabajo, como parte del proceso para generar
            políticas y apoyos dirigidos directamente a nuestro sector.
          </li>
          <li>
            Actualizar la información en el artículo en Wikipedia con respecto
            al cómic colombiano:{' '}
            <a
              href="https://es.wikipedia.org/wiki/Historieta_en_Colombia"
              target='="_blank'
              className={link}
            >
              Historieta en Colombia
            </a>
          </li>
          <li>
            Iniciar el proceso de recolección y organización de datos para la
            exposición virtual{' '}
            <b>
              <i>“Línea del tiempo de los 100 años del cómic en Colombia”</i>
            </b>
            , la exposición que se llevará a cabo en SOFA 2024
            <b>
              <i>“Salón de la Fama del Cómic Colombiano”</i>
            </b>
            , así cómo también para dar comienzo a la iniciativa para llevar a
            cabo la premiación o reconocimientos al cómic nacional.
          </li>
          <li>
            Actualizar la información en las páginas web{' '}
            <a
              href="https://www.comiccolombiano.com/"
              target='="_blank'
              className={link}
            >
              www.comiccolombiano.com
            </a>{' '}
            y{' '}
            <a
              href="https://bogotacomicsblog.blogspot.com/"
              target='="_blank'
              className={link}
            >
              bogotacomicsblog.blogspot.com
            </a>
            .
          </li>
        </ul>
        <br />
        <div className={title}>Privacidad de los datos</div>
        <br />
        La información recolectada se usará exclusivamente para los fines
        enunciados anteriormente. Al participar de este proceso los autores
        tienen completa libertad de permitir o no el uso público de sus datos
        como artista o de sus obras a través de los portales web asociados a
        esta iniciativa. Es importante tener en cuenta que la mayor cantidad de
        lo datos se usará de forma privada, solo algunos datos seleccionados se
        usaran de forma pública con el fin de brindar información de interés
        general.
        <br />
        <br />
        Al llenar el formulario los autores encontraran campos obligatorios, los
        cuales deberán llenar con la información requerida, como también
        encontraran campos opcionales, en dónde el autor tiene completa libertad
        de suministrar o no dicha información.
        <br />
        <br />
        Para empezar a llenar el formulario, manifiesta estar de acuerdo con la
        información suministrada.
        <br />
      </div>
      <div className={buttonContainer}>
        <div className={`${button} ${button0}`} onClick={onClick}>
          Si, estoy de acuerdo.
        </div>
      </div>
    </div>
  )
}

export default AuthModal
