import React from 'react'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Styles
import { animation } from './style.module.scss'

interface Props {
  className?: string
}

const Spinner: React.FC<Props> = ({ className }) => {
  return (
    <FontAwesomeIcon
      className={[animation, className].join(' ')}
      icon={faSpinner}
    />
  )
}

export default Spinner
