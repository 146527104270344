import React, { ReactNode } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

// Style
import {
  input,
  inputLabel,
  wrapper,
} from './style.module.scss'

interface Props {
  label?: string | ReactNode
  register: UseFormRegisterReturn
  defaultChecked?: boolean
}

const RegisterSimpleCheck: React.FC<Props> = ({
  label,
  register,
  defaultChecked
}) => {
  return (
    <div className={wrapper}>
      <input {...register} className={input} type="checkbox" defaultChecked={defaultChecked}/>
      <div className={inputLabel}>{label}</div>
    </div>
  )
}

export default RegisterSimpleCheck
