// extracted by mini-css-extract-plugin
export var button = "style-module--button--81b44";
export var buttonIcon = "style-module--button-icon--bbc35";
export var buttonLabel = "style-module--button-label--9f527";
export var close = "style-module--close--f596c";
export var description = "style-module--description--70bb9";
export var descriptions = "style-module--descriptions--6c58e";
export var grid = "style-module--grid--2d90f";
export var hidden = "style-module--hidden--6eb02";
export var logos = "style-module--logos--22664";
export var logosImg = "style-module--logos-img--4fd68";
export var participants = "style-module--participants--a11e2";
export var subtitle = "style-module--subtitle--52745";
export var title = "style-module--title--166f8";
export var wrapper = "style-module--wrapper--809e3";