import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useFormContext, UseFormRegisterReturn } from 'react-hook-form'

// Components
import { normalizeText } from '../../../utils/utils'

// Context
import RegisterContext from '../../../context/Register/Register.context'

// Style
import {
  long,
  ghost,
  input,
  inputLabel,
  inputWrapper,
  inputDescription,
  wrapper,
  visible,
  visibleElement,
  errorLabel,
} from './style.module.scss'

interface Props {
  name: string
  register: UseFormRegisterReturn
  error?: string
  justify?: boolean
  label?: string | ReactNode
  description?: string | ReactNode
  external?: number | null
}

type StatusElement = {
  id: number
  text: string
}

const RegisterStatusPicker: React.FC<Props> = ({
  name,
  label,
  error,
  justify,
  external,
  register,
  description,
}) => {
  const { statuses } = useContext(RegisterContext)

  const [list, setList] = useState<StatusElement[]>()
  const [filter, setFilter] = useState<StatusElement[]>()

  const ref = useRef<HTMLInputElement | null>(null)
  const { setValue } = useFormContext()

  const loadStatuses = async () => {
    const statusOptions = statuses.map((s) => {
      return { id: s.id, text: s.name  }
    })
    setList(statusOptions)
  }

  const filterStatus = (query: string) => {
    const filtered = list
      ?.filter((c) => normalizeText(c.text).includes(normalizeText(query)))
    setFilter(filtered)
  }

  const setStatus = (text: string, id: number) => {
    if (ref.current) ref.current.value = text
    setValue(name, id, { shouldValidate: true })
  }

  useEffect(() => {
    loadStatuses()
  }, [statuses])

  useEffect(() => {
    setFilter(list)
  }, [list])

  useEffect(() => {
    if (typeof external !== 'undefined' && ref.current) {
      if (external) {
        const status = list?.find((c) => c.id === external)
        if (status) ref.current.value = status?.text
        else ref.current.value = ''
      } else ref.current.value = ''
    }
  }, [external, list])

  return (
    <div className={wrapper}>
      <div className={inputLabel}>{label}</div>
      <div className={inputWrapper}>
        <input
          className={input}
          onChange={(e) => filterStatus(e.target.value)}
          ref={ref}
        />
        <div className={visible}>
          {filter?.map((c) => (
            <div
              key={c.id}
              className={visibleElement}
              onClick={() => setStatus(c.text, c.id)}
            >
              {c.text}
            </div>
          ))}
        </div>
      </div>
      <div className={[inputDescription, justify ? long : undefined].join(' ')}>
        {error ? <span className={errorLabel}>{error}</span> : description}
      </div>
      <input {...register} className={ghost} />
    </div>
  )
}

export default RegisterStatusPicker
