import React, { useContext } from 'react'
import {
  faClose,
  faBookmark,
  faBookOpen,
  faBookAtlas,
  faBookOpenReader,
  faBookBookmark,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Context
import RegisterContext from '../../../context/Register/Register.context'

// Components
import { RegisterForm } from '../../templates/Register'

// Style
import {
  grid,
  close,
  title,
  button,
  buttonIcon,
  buttonLabel,
  hidden,
  wrapper,
  descriptions,
} from './style.module.scss'

interface Props {
  active: boolean
  openForm: (form: RegisterForm) => void
  closeForm: () => void
}

const Registerwork: React.FC<Props> = ({ active, openForm, closeForm }) => {
  const { updateVariant } = useContext(RegisterContext)
  return (
    <div className={[wrapper, active ? undefined : hidden].join(' ')}>
      <div className={title}>
        Tipo de Obra
        <FontAwesomeIcon icon={faClose} className={close} onClick={closeForm} />
      </div>
      <div className={descriptions}>
        <i>
          Selcciona el tipo de obra que mejor se acople a la obra que vas a
          inscribir.
        </i>
      </div>
      <div className={grid}>
        <div className={button} onClick={() => openForm('OneShot')}>
          <div className={buttonIcon}>
            <FontAwesomeIcon icon={faBookOpen} />
          </div>
          <div className={buttonLabel}>Cómic Autoconclusivo o One-Shot</div>
        </div>
        <div className={button} onClick={() => openForm('Novel')}>
          <div className={buttonIcon}>
            <FontAwesomeIcon icon={faBookOpenReader} />
          </div>
          <div className={buttonLabel}>Novela Gráfica</div>
        </div>
        <div className={button} onClick={() => openForm('Series')}>
          <div className={buttonIcon}>
            <FontAwesomeIcon icon={faBookBookmark} />
          </div>
          <div className={buttonLabel}>Series</div>
        </div>
        <div className={button} onClick={() => openForm('Chapter')}>
          <div className={buttonIcon}>
            <FontAwesomeIcon icon={faBookmark} />
          </div>
          <div className={buttonLabel}>
            Capítulo, edición especial o volumen compilatorio de una <b>Series
            Inscrita</b>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Registerwork
